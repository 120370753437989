import MulticircleLoading from 'components/atomic/MulticircleLoading/MulticircleLoading';
import './FullPageLoading.sass';

function FullPageLoading({ opacity = 1 }) {
    return (
        <div
            className="full-page-loading"
            style={{ backgroundColor: `rgba(255,255,255,${opacity})` }}
        >
            <MulticircleLoading />
        </div>
    );
}

export default FullPageLoading;
