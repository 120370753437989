import { Avatar, Divider, Dropdown, Menu } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React from 'react';
import { getNavItem } from 'components/Layout/AppLayout/AppLayout';
import { LogoutOutlined } from '@ant-design/icons';
import hustLogo from 'assets/images/header/hust-logo.jpeg';
import { TOKEN_KEY } from 'app-configs';
import { useSelector } from 'react-redux';
import logo from 'assets/images/header/logo.png';
const userDropdownItems = [getNavItem('Đăng xuất', '/auth/logout', <LogoutOutlined />, null)];

const onClickUserAvatar = (item) => {
    if (item?.key == '/auth/logout') {
        handleLogout();
    }
};

function handleLogout() {
    localStorage.removeItem(TOKEN_KEY);
    window.location.reload(false);
}

export default function (props) {
    const userDetail = useSelector((state) => state?.user?.profile);

    return (
        <Header
            style={{
                padding: '0px 10px',
                backgroundColor: '#EBF2F6',
                height: '60px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #DCDEE0',
            }}
        >
            <img
                className="b4e-logo"
                src={logo}
                style={{
                    width: '161px',
                    padding: '5px',
                }}
            ></img>

            <div
                style={{
                    marginRight: '20px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        marginRight: '10px',
                        fontWeight: '550',
                        userSelect: 'none',
                        color: '#13435B',
                    }}
                >
                    {userDetail?.name}
                </div>
                <Dropdown
                    overlay={<Menu items={userDropdownItems} onClick={onClickUserAvatar} />}
                    placement="bottomRight"
                    trigger={['click']}
                    arrow={{ pointAtCenter: true }}
                >
                    <Avatar size={40} src={hustLogo} className="hover-pointer" />
                </Dropdown>
            </div>
        </Header>
    );
}
