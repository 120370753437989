import { Divider, Image, Layout } from 'antd';
import 'antd/dist/antd.css';
import { SIDER_COLLAPSE } from 'app-configs';
import institutionLogo from 'assets/images/header/logo-daotao.svg';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';
import AppHeader from '../AppHeader';
import styles from './AppLayout.module.sass';

import iconListA from 'assets/images/icons/Mask-Group-2-a.svg';
import iconList from 'assets/images/icons/Mask-Group-2.svg';

import iconChopA from 'assets/images/icons/Mask-Group-3-a.svg';
import iconChop from 'assets/images/icons/Mask-Group-3.svg';

import iconContractA from 'assets/images/icons/Mask-Group-4-a.svg';
import iconContract from 'assets/images/icons/Mask-Group-4.svg';

import iconEmailA from 'assets/images/icons/Mask-Group-5-a.svg';
import iconEmail from 'assets/images/icons/Mask-Group-5.svg';

import iconStatistic from 'assets/images/icons/xanh.svg';
import iconStatisticA from 'assets/images/icons/trang.svg';
const { Header, Sider, Content } = Layout;
let cx = classNames.bind(styles);
export function getNavItem(label, key, icon, activeIcon, children, type) {
    return {
        key,
        icon,
        activeIcon,
        children,
        label,
        type,
    };
}
const sliderItems = [
    getNavItem('Danh sách tài khoản', '/accounts/list', iconList, iconListA, null),
    getNavItem('Cấu hình chứng nhận', '/config/select-ceft', iconChop, iconChopA, null),
    getNavItem('Cấu hình hợp đồng', '/config/blockchain', iconContract, iconContractA, null),
    // getNavItem('Quản lí chứng nhận', '/cert/manager', iconStatistic, iconStatisticA, null),
    getNavItem('Liên kết định danh', '/config/linked-id', iconStatistic, iconStatisticA, null),
    getNavItem('Quản lý email', '/config/email', iconEmail, iconEmailA, null),
];

function AppLayout({ children, match }) {
    const [collapsed, setCollapsed] = useState(localStorage.getItem(SIDER_COLLAPSE) ?? false);
    const history = useHistory();
    const currentRouter = useSelector((state) => state.router.location);
    const [selectedSider, setSelectedSider] = useState(getSelectedNav());

    function getSelectedNav() {
        if (currentRouter?.pathname.includes('/cerf/detail/')) {
            return '/cert/manager';
        }
        if (currentRouter?.pathname.includes('/config/sign-ceft/')) {
            return '/config/select-ceft';
        }
        return currentRouter?.pathname;
    }

    useEffect(() => {
        setSelectedSider(getSelectedNav());
    }, [currentRouter]);

    useEffect(() => {
        console.log('selectedSider: ', selectedSider);
    }, [selectedSider]);

    return (
        <Layout>
            <AppHeader />
            <Layout>
                <Sider
                    className={cx('slider-style')}
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                >
                    <Link to={'/'} className={cx('slide-link-nav')}>
                        <Image
                            className={cx('icon-home-page')}
                            width="100%"
                            src={institutionLogo}
                            preview={false}
                        />
                    </Link>
                    <Divider style={{ margin: 0 }} />

                    {sliderItems.map((item, index) => {
                        return (
                            <div className={cx('top-nav')} key={index}>
                                <NavLink
                                    to={item.key}
                                    className={cx(
                                        item.key === selectedSider ? 'nav-highlight' : 'nav-list',
                                    )}
                                >
                                    <img
                                        src={
                                            item.key === selectedSider ? item.activeIcon : item.icon
                                        }
                                        className={cx('icon-nav')}
                                    ></img>
                                    {item.label}
                                </NavLink>
                            </div>
                        );
                    })}
                </Sider>

                <Content className={cx('content-layout')}>
                    <div style={{ paddingBottom: '5px' }}>{children}</div>

                    <a href="https://bkc-labs.io/" target="_blank" className={cx('bkc-span')}>
                        © BKCLab {new Date().getFullYear()}
                    </a>
                </Content>
            </Layout>
        </Layout>
    );
}

export default AppLayout;
