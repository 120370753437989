import { lazy } from 'react';
import AppLayout from 'components/Layout/AppLayout/AppLayout';
import { initModules } from 'router/index';

export const createTemplateModule = {
    key: 'createTemplate',
    path: 'CreateTemplate',
};

export default {
    path: '/tempate/create',
    exact: true,
    isPrivate: true,
    layout: null,
    component: lazy(async () => {
        await initModules([createTemplateModule], 'app');
        return import('./pages/CreateTemplate');
    }),
};

export const childRoutes = [
    // {
    //     path: '/v',
    //     exact: true,
    //     isPrivate: true,
    //     layout: AppLayout,
    //     component: lazy(async () => {
    //         await initModules([featureModule], 'app');
    //         return import('./pages/CreateTemplate');
    //     }),
    // },
];
