import React from 'react';
import { MODULES } from 'app-configs';
import { lazy } from 'react';
import { initModules } from 'router/index';

export const accountManagerModule = {
    key: 'accountManager',
    path: 'AccountsManager',
};

export default {
    // path: '/accounts/add',
    // exact: true,
    // isPrivate: true,
    // component: lazy(async () => {
    //     await initModules([accountManagerModule], 'app');
    //     return import('./pages/AddAccount');
    // }),
    path: '/accounts/list',
    exact: true,
    isPrivate: true,
    component: lazy(async () => {
        await initModules([accountManagerModule], 'app');
        return import('./pages/ListAccount/ListAccount');
    }),
};

export const childRoutes = [
    {
        path: '/accounts/add',
        exact: true,
        isPrivate: true,
        component: lazy(async () => {
            await initModules([accountManagerModule], 'app');
            return import('./pages/AddAccount/AddAccount');
        }),
    },
];
