import React from 'react';
import { MODULES } from 'app-configs';
import { lazy } from 'react';
import { initModules } from 'router/index';

export const configBlockChainModule = {
    key: 'configBlockChain',
    path: 'ConfigBlockChain',
};

export default {
    path: '/config/blockchain',
    exact: true,
    isPrivate: true,
    component: lazy(async () => {
        await initModules([configBlockChainModule], 'app');
        return import('./pages/ConfigBlockChain');
    }),
};

export const childRoutes = [];
