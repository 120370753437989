import { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { notification } from 'antd';
import 'antd/dist/antd.css';
import { REQUEST_STATE } from 'app-configs';
import AppLayout from 'components/Layout/AppLayout/AppLayout';
import AuthLayout from 'components/Layout/AuthLayout/AuthLayout';
import FullPageLoading from 'components/Loading/FullPageLoading/FullPageLoading';
import NotFound from 'components/NotFound';
import { ConnectedRouter } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_NOTIFY_STATE } from 'redux/actions/notify';
import { listAppRoutes, listAuthenticationRoutes } from 'router';
import AppRoute from './containers/app/AppRoute';
import AuthenticationRoute from './containers/authentication/AuthenticationRoute';
import history from './helpers/history';

console.debug('listAppRoutes =>', listAppRoutes);
console.debug('listAuthenticationRoutes =>', listAuthenticationRoutes);

function App() {
    const dispatch = useDispatch();

    const notify = useSelector((state) => state.notify);
    const userProfile = useSelector((state) => state.user?.profile);
    const initialNavigation = '/accounts/list';

    useEffect(() => {
        if (notify.requestState === REQUEST_STATE.SUCCESS) {
            notification.success({
                message: 'Thành công',
                description: notify.message,
            });
            dispatch(RESET_NOTIFY_STATE());
        } else if (notify.requestState === REQUEST_STATE.ERROR) {
            notification.error({
                message: 'Thất bại',
                description: notify?.message ?? 'Một lỗi đã xảy ra',
            });
            dispatch(RESET_NOTIFY_STATE());
        }
    }, [notify.requestState]);
    console.log(listAppRoutes);
    return (
        <ConnectedRouter history={history}>
            <Suspense fallback={<FullPageLoading />}>
                <Switch>
                    {listAppRoutes.map(({ path, exactContainer = true }) => (
                        <Route
                            path={path}
                            render={() => (
                                <AppLayout>
                                    <AppRoute />
                                </AppLayout>
                            )}
                            key={path}
                            exact={exactContainer}
                        />
                    ))}
                    {listAuthenticationRoutes.map(({ path, exactContainer = true }) => (
                        <Route
                            path={path}
                            render={() => (
                                <AuthLayout>
                                    <AuthenticationRoute />
                                </AuthLayout>
                            )}
                            key={path}
                            exact={exactContainer}
                        />
                    ))}
                    <Redirect exact from="/" to={initialNavigation} />
                    <Route exact={true} path="/not-found-page">
                        <NotFound />
                    </Route>
                    <Redirect from="*" to="/not-found-page" />
                </Switch>
            </Suspense>
        </ConnectedRouter>
    );
}

export default App;
