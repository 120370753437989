import React from 'react';
import classNames from 'classnames/bind';
import styles from './AuthLayout.module.sass';
import LogoB4e from 'assets/images/login/logob4e.png';
import B4eBackground from 'assets/images/login/managerB4ebg.png';
import { Col, Row } from 'antd';
let cx = classNames.bind(styles);
function AuthLayout({ children }) {
    return (
        <Row
            className={cx('login__box')}
            style={{
                zIndex: 99,
            }}
        >
            <Col span={8}>
                <div className={cx('auth__form', '--welcome')}>
                    <img src={B4eBackground} className={cx('login-background')}></img>
                    <div className={cx('flex-column', 'welcome-wrapper')}>
                        <img src={LogoB4e} className={cx('auth__welcome-img')}></img>

                        <div className={cx('auth__welcome')}>
                            <span
                                style={{
                                    marginRight: '6px',
                                }}
                            >
                                Chào mừng bạn đến với B4E Manager
                            </span>
                        </div>
                        <div className={cx('auth__welcome-desc')}>
                            Hệ thống tạo và xác thực chứng nhận số sử dụng công nghệ blockchain và
                            chữ kí số.
                        </div>
                    </div>
                </div>
            </Col>
            <Col span={16}>{children}</Col>
        </Row>
    );
}

export default AuthLayout;
