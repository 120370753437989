import React from 'react';
import { MODULES } from 'app-configs';
import { lazy } from 'react';
import { initModules } from 'router/index';

export const certManagerModule = {
    key: 'certManager',
    path: 'CertManager',
};

export default {
    path: '/cert/manager',
    exact: true,
    isPrivate: true,
    component: lazy(async () => {
        await initModules([certManagerModule], 'app');
        return import('./pages/ListBatch');
    }),
};

export const childRoutes = [
    {
        path: '/cerf/detail/:id',
        exact: true,
        isPrivate: true,
        component: lazy(async () => {
            await initModules([certManagerModule], 'app');
            return import('./pages/DetailBatch/DetailBatch');
        }),
    },
];
