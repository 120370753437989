import React from 'react';
import { MODULES } from 'app-configs';
import { lazy } from 'react';
import { initModules } from 'router/index';
import { configSignModule } from '../ConfigSign/route';

export const statisticModule = {
    key: 'statistic',
    path: 'Statistic',
};

export default {
    path: '/statistic',
    exact: true,
    isPrivate: true,
    component: lazy(async () => {
        await initModules([statisticModule, configSignModule], 'app');
        return import('./pages/chart');
    }),
};

export const childRoutes = [];
