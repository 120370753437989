import { lazy } from 'react';
import { initModules } from 'router/index';

export const configEmailModule = {
    key: 'configEmail',
    path: 'ConfigEmail',
};

export default {
    path: '/config/email',
    exact: true,
    isPrivate: true,
    component: lazy(async () => {
        await initModules([configEmailModule], 'app');
        return import('./pages/ConfigEmail');
    }),
};

export const childRoutes = [];
