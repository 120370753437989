export const Configs = {
    BASE_API: process.env.REACT_APP_BASE_API_URL,
    DOMAIN: '',

    CURRENT_PAGE: 1,
    FILE_MAXIMUM: 5, //MB
    PAGE_SIZE_20: 20,
    PAGE_SIZE_4: 4,
};

export const REQUEST_STATE = {
    ERROR: 'ERROR',
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
};

// key store in localStorage, Cookies, Session
export const I18LANGUAGE_KEY = 'i18nextLng';
export const TOKEN_KEY = 'authencation_COBGBRPIIP';
export const SIDER_COLLAPSE = 'siderCollapse';

export const ACTION_TYPE = {
    CREATE: 'CREATE',
    LIST: 'LIST',
    VIEW: 'VIEW',
    DELETE: 'DELETE',
    UPDATE: 'UPDATE',
    UNMOUNT: 'UNMOUNT',
};

export const VALID_IMAGE_TYPES = ['image/gif', 'image/jpeg', 'image/png'];

// 1. UI flow: add router
export const USER_ROLES = {
    UPLOAD: 1,
    SIGN: 2,
    UPLOAD_TO_BLOCKCHAIN: 3,
};

export const ACCOUNT_PERMISSION = {
    UPLOAD: {
        value: 'UPLOADER',
        text: 'Tải tài liệu',
        color: '#828FD1',
    },
    SIGN: {
        value: 'SIGNER',
        text: 'Ký',
        color: '#087AB6',
    },
    UPLOAD_TO_BLOCKCHAIN: {
        value: 'PUBLISHER',
        text: 'Tải lên blockchain',
        color: '#49C2C6',
    },
};

export const TEMPLATE_PERMISSION = {
    upload: {
        value: 'upload',
        text: 'Tải tài liệu',
        color: '#828FD1',
    },
    sign: {
        value: 'signer',
        text: 'Ký',
        color: '#087AB6',
    },
    publish: {
        value: 'publisher',
        text: 'Tải lên blockchain',
        color: '#49C2C6',
    },
};

export const STUDENT_CEFT_STATUS = {
    DATA_UPLOAD: 'DATA_UPLOAD_0',
    PDF_GENERATED: 'PDF_GENERATED_1',
    PENDING: 'PENDING_2',
    NEED_SIGN: 'NEED_SIGN_3',
    ERROR_SIGN: '4 - ERROR_SIGN',
    COMPLETE_SIGN: 'COMPLETE_SIGN_4',
    WRAP: 'WRAP_5',
    UPLOAD_TO_BC: 'UPLOAD_TO_BC_6',
    REVOKE: 'REVOKE_7',
    ERROR_SOME_FILE_SEND_EMAIL: 'ERROR_SOME_FILE_SEND_EMAIL_8',
    UPLOAD_JSON_CERT: 'UPLOAD_JSON_CERT_9',
    SEND_EMAIL: 'SEND_EMAIL_10',
    SEND_EMAIL_OLD_KEY: '8 - SEND_EMAIL', // TODO: do dữ liệu cũ, code cập nhật status nên bị lỗi. Tạm thời vá bug như này.
    SEND_EMAIL_FAIL: 'SEND_EMAIL_FAIL_11',
};

// 1. UI flow: add router
export const CERT_STATUS = [
    {
        text: 'Chưa ký xong',
        value: [STUDENT_CEFT_STATUS.NEED_SIGN, STUDENT_CEFT_STATUS.PENDING],
    },
    {
        text: 'Đã ký xong',
        value: STUDENT_CEFT_STATUS.COMPLETE_SIGN,
    },
    {
        text: 'Đã tải lên blockchain',
        value: STUDENT_CEFT_STATUS.UPLOAD_TO_BC,
    },
    {
        text: 'Đã gửi email',
        value: STUDENT_CEFT_STATUS.SEND_EMAIL,
    },
];

export const EXPIRED_TIME_CONTRACT = 0;

export const ADDRESS_OWNER_MANAGEMENT = {
    56: '0xfBb4F8AFa108B4A3B1AD2C80aD77045611f5be86',
    1: '',
    11155111: '0x8F5B5661DEB0E390AB5cDE10Ec94B50a1A3Fd221',
};

export const UploadTypes = {
    PDF: 'pdf',
    FONT: 'font',
};

export const SignTypes = {
    VISIBLE: {
        type: 'Visible',
        desc: 'Ký hiện',
    },
    INVISIBLE: {
        type: 'InVisible',
        desc: 'Ký ẩn',
    },
};

export const ATTACHMENT_TYPES = {
    TEXT: 'TEXT',
    QRCODE: 'QRCODE',
    SIGN: 'SIGN',
};

export const ATTACHMENT_STATUS = {
    DELETED: 'delete',
    ACTIVATE: 'activate',
    DEACTIVATE: 'deactivate',
};

export const NETWORK_CONFIG = {
    56: {
        chainId: 56,
        chainName: 'BNB Quick Node',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
        },
        rpcUrls: [
            'https://bitter-responsive-tent.bsc.discover.quiknode.pro/c4c66566289c3353f77e19e921e2d3240a490aa6/',
        ],
        blockExplorerUrls: ['https://bscscan.com/'],
    },
    1: {
        chainId: 1,
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://eth.llamarpc.com'],
        blockExplorerUrls: ['https://etherscan.io'],
    },
    11155111: {
        chainId: 11155111,
        chainName: 'Sepolia',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://eth-sepolia.g.alchemy.com/v2/Oxk0rnqZ8XkzQXANC4NRA8WWg9SHhher'],
        blockExplorerUrls: ['https://sepolia.etherscan.io'],
    },
};
